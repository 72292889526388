






















import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'DetermineIonCharge',
  components: {},
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        electronLost1: '',
        electronGain1: '',
        electronLost2: '',
        electronGain2: '',
      },
      questions: [
        {
          text: 'a) What is the resultant charge when 1 electron is lost:',
          inputName: 'electronLost1',
        },
        {
          text: 'b) What is the resultant charge when 1 electron is gained:',
          inputName: 'electronGain1',
        },
        {
          text: 'c) What is the resultant charge when 2 electrons are lost:',
          inputName: 'electronLost2',
        },
        {
          text: 'd) What is the resultant charge when 2 electrons are gained:',
          inputName: 'electronGain2',
        },
      ],
    };
  },
  computed: {},
};
